import React from 'react'

import LtiApplyTheme from './LtiApplyTheme'
import {Picker} from './Picker'
import LaunchRetriever from './LaunchRetriever'
import Liking from "./Liking";
import LtiHeighLimit from "./LtiHeighLimit";
import {ToggleGroup} from "@instructure/ui-toggle-details";
import LikeUsers from "./LikeUsers";

const settings = {
  'https://localhost:3000': {
    'server': process.env.REACT_APP_LIKING_URL
  },
  'https://feedback.wyelearning.com': {
    'server': '/app'
  }
}

class App extends React.Component {
  state = {
    jwt: null,
    needsToken: false,
    error: null,
    brandConfig: null,
    returnUrl: null,
    highContrast: false,
    data: null
  }

  constructor(props) {
    super(props)
    const origin = window.origin
    if (settings[origin]) {
      let server = settings[origin].server
      // If it's a relative URL then prefix the current URL (using origin)
      if (!server.startsWith("http")) {
        server = window.origin + server
      }
      this.server = server
    }
  }
  componentDidMount() {
    window.addEventListener("message", (event) => {
      console.log(event);
    })
    window.parent.postMessage(JSON.stringify({"subject": "lti.fetchUrl"}), "*")
  }

  updateData = (jwt, token) => {
    return new Promise(resolve => this.setState({
      // The Canvas branding.
      brandConfig: jwt.custom_brand_config,
      // If the user prefers the high contrast version of Canvas
      highContrast: jwt.custom_high_contrast === 'true',
      // How this LTI was launched? (basic-lti-launch-request/ContentItemSelectionRequest)
      picker: jwt.lti_message_type === 'ContentItemSelectionRequest',
      // When picking an item where should we send the response to.
      returnUrl: jwt.content_item_return_url,
      // The data included when picking an item.
      data: jwt.data,
      // The actual jwt
      token: token
    }, resolve))
  }

  render() {
    return (
      <LaunchRetriever ltiServer={this.server} handleJwt={this.updateData}>
        <LtiApplyTheme url={this.state.brandConfig} highContrast={this.state.highContrast}>
          {this.state.picker ?
              <Picker returnUrl={this.state.returnUrl} data={this.state.data} server={this.server}/> :
              <LtiHeighLimit resizing={true}>
                <ToggleGroup
                    border={false} size='small'
                    toggleLabel="Toggle list of likes."
                    summary={
                      <Liking server={this.server} jwt={this.state.token}/>
                    }
                >
                  <LikeUsers server={this.server} jwt={this.state.token}/>
                </ToggleGroup>
              </LtiHeighLimit>
          }
        </LtiApplyTheme>
    </LaunchRetriever>
    )
  }
}

export default App
