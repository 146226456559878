import React from 'react'
import * as PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import {Button} from '@instructure/ui-buttons'
import {View} from '@instructure/ui-view'
import {Flex} from '@instructure/ui-flex'
import {Link} from "@instructure/ui-link";
import {IconQuestionLine} from "@instructure/ui-icons";


export class Picker extends React.Component {

    static propTypes = {
        /**
         * The LTI return URL passed on the launch.
         */
        returnUrl: PropTypes.string,
        /**
         * The data that was passed on the LTI launch to pass back.
         */
        data: PropTypes.string,
        /**
         * The URL of the server running the backend application.
         */
        server: PropTypes.string.isRequired
    }

    static defaultProps = {
        data: ''
    }

    // Check accepted types
    // content items...
    state = {
        message: ''
    }


    constructor(props, context) {
        super(props, context)
        this.formRef = React.createRef()
    }

    onClick = async () => {
        await this.setState({
            items: JSON.stringify(this.makeContentItem(true)),
            message: 'Inserted'

        })
        this.formRef.current.submit()
    }

    onCancelClick = async () => {
        await this.setState({
            items: JSON.stringify(this.makeContentItem(false)),
            message: 'Cancelled'
        })
        this.formRef.current.submit()
    }

    makeContentItem = (add) => {
        // Don't return anything if empty
        let item = []
        if (add) {
            const uuid = uuidv4()
            item.push({
                '@type': 'LtiLinkItem',
                'mediaType': 'application/vnd.ims.lti.v1.ltilink',
                'placementAdvice': {
                    'displayWidth': '100%',
                    // As we have padding on ToggleGroup we need a little more space
                    // 50 was enough before switching to the expander
                    'displayHeight': 70,
                    'presentationDocumentTarget': 'iframe'
                },
                // You can't pass through custom parameters on LTI 1.0 (maybe works on 1.3)
                // This gets included in the parameters that are signed by the LTI launch so it can't be changed
                // by the end user on launch
                'url': this.props.server+ '/launch?code='+uuid
            })
        }
        return {
            '@context': 'http://purl.imsglobal.org/ctx/lti/v1/ContentItem',
            '@graph': item
        }
    }

    toEmpty = (value) => {
        // This is so that we don't put null values into input elements.
        return value !== null && value !== undefined ? value : ''
    }
    

    render() {
        const returnUrl = (this.props.returnUrl)
        const noReturn = !(returnUrl && returnUrl.length > 0)

        return <View as='div' height='100%'>
            <form action={returnUrl} method='POST' ref={this.formRef}>
                <input type='hidden' name='lti_version' value='LTI-1p0'/>
                <input type='hidden' name='lti_message_type' value='ContentItemSelection'/>
                <input type='hidden' name='data' value={this.toEmpty(this.props.data)}/>
                <input type='hidden' name='content_items' value={this.toEmpty(this.state.items)}/>
                <input type='hidden' name='lti_msg' value={this.toEmpty(this.state.message)}/>
            </form>
            <Flex direction="column" height='100%'>
                <Flex.Item padding='small'>
                    <Flex>
                        <Flex.Item shouldGrow align='start'>
                            <Link renderIcon={IconQuestionLine} margin='0 medium' target='_blank'
                                  href='https://wyelearning.com/support/liking/'>Help</Link>
                        </Flex.Item>
                        <Flex.Item>
                            <Button onClick={this.onCancelClick} margin='xx-small'
                                    interaction={noReturn ? 'disabled' : 'enabled'}>Cancel</Button>
                            <Button color='primary' onClick={this.onClick} margin='xx-small'
                                    interaction={noReturn ? 'disabled' : 'enabled'}>Insert</Button>

                        </Flex.Item>
                    </Flex>
                </Flex.Item>
            </Flex>
        </View>
    }

}
