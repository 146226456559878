import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import App from './App'

Sentry.init({
    // dsn: 'https://4d2661a9c2244a3d8c016cca0bd55278@o459966.ingest.sentry.io/5920441',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
})

ReactDOM.render(<App />, document.getElementById('app'))
