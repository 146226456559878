import React from "react"
import PropTypes from "prop-types"
import {IconLikeLine, IconLikeSolid} from "@instructure/ui-icons";
import {IconButton} from "@instructure/ui-buttons";
import {Spinner} from "@instructure/ui-spinner";
import {Text} from "@instructure/ui-text";
import {Flex} from "@instructure/ui-flex";

export default class Liking extends React.Component {

    static propTypes = {
        server: PropTypes.string.isRequired,
        jwt: PropTypes.string.isRequired
    }

    state = {
        error: null,
        loading: false,
        liked: false,
        likes: 0
    }

    componentDidMount() {
        this.loadLikes();
    }

    loadLikes() {
        this.setState({loading: true, error: null})
        fetch(this.props.server + '/api/like', {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this.props.jwt
            }
        }).then(response =>  {
            if (!response.ok) {
              throw new Error(response.statusText)
            }
            return response
        }).then(response => response.json()
        ).then(json => {
            this.setState({likes: json.likes, liked: json.liked})
        }).catch(error => {
            this.setState({error: "Failed to load likes."})
        }).finally(() => this.setState({loading: false}))
    }

    like = async () => {
        this.setState({loading: true, error: null})
        fetch(this.props.server + '/api/like', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this.props.jwt
            }
        }).then(response => {
            if(response.ok) {
                this.setState((state) => ({ liked: true, likes: state.likes+1}))
            } else {
                if (response.status === 409) {
                    this.loadLikes()
                }
                throw new Error(response.statusText)
            }
        }).catch(error => {
            this.setState({error: "Failed to like."})
        }).finally(() => this.setState({loading: false}))
    }

    unlike = async () => {
        this.setState({loading: true, error: null})
        fetch(this.props.server + '/api/like', {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this.props.jwt
            }
        }).then(response => {
            if(response.ok) {
                this.setState((state) => ({ liked: false, likes: state.likes-1}))
            } else {
                if (response.status === 404) {
                    this.loadLikes()
                }
                throw new Error(response.statusText)
            }
        }).catch(error => {
            this.setState({error: "Failed to un-like."})
        }).finally(() => this.setState({loading: false}))
    }

    render() {
        return <Flex justifyItems="end">
            <Flex.Item margin='none medium'>
                <Text  color="danger">{this.state.error}</Text>
            </Flex.Item>
            <Flex.Item>
                {this.state.loading && <Spinner size="small" margin="none small none none" renderTitle="Loading likes."/>}
                <Text>
                {this.state.likes}
                </Text>
            </Flex.Item>
            <Flex.Item>
                    {this.state.liked ?
                        <IconButton withBackground={false} withBorder={false} screenReaderLabel="Unlike content"
                                    margin="xx-small" onClick={this.unlike} interaction={this.state.loading?'disabled':'enabled'}>
                            <IconLikeSolid/>
                        </IconButton>
                        :
                        <IconButton withBackground={false} withBorder={false} screenReaderLabel="Like content"
                                    margin="xx-small" onClick={this.like} interaction={this.state.loading?'disabled':'enabled'}>
                            <IconLikeLine/>
                        </IconButton>
                    }
            </Flex.Item>
        </Flex>
    }
}