import React from "react"
import {View} from "@instructure/ui-view";
import {Spinner} from "@instructure/ui-spinner";
import {List} from "@instructure/ui-list";
import {Text} from "@instructure/ui-text";
import {Avatar} from "@instructure/ui-avatar";

export default class LikeUsers extends React.Component {

    state = {
        loading: false,
        error: null,
        users: []
    }

    componentDidMount() {
        this.setState({loading: true, error: null})
        fetch(this.props.server + '/api/like/users', {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this.props.jwt
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error(response.statusText)
            }
            return response
        }).then(response => response.json()
        ).then(json => {
            this.setState({users: json})
        }).catch(error => {
            this.setState({error: "Failed to load likes."})
        }).finally(() => this.setState({loading: false}))
    }

    renderLoading() {
        return <Spinner size='x-small' margin='medium' renderTitle='Loading users.'/>
    }

    renderUsers() {
        return <View as="div" margin='small' padding='small'>
            {this.state.users.length === 0 ?
                <Text>No likes yet.</Text> :
                <List margin='none' size='small' isUnstyled>
                    {this.state.users.map(user =>
                        <List.Item key={user.id}>
                            <Avatar size='x-small' name={this.renderUser(user.name)} src={user.avatar} margin="xx-small small" />
                            {this.renderUser(user.name)}
                        </List.Item>
                    )}
                < /List>
            }
        </View>
    }
    
    renderUser(name) {
        return name?name:"Unknown";
    }

    render() {
        return this.state.loading ? this.renderLoading() : this.renderUsers()
    }
}